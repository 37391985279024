.post-load {
  transition: transform 2s ease-in-out;
  transform: translate(0, 0) !important;
}

#preloader {
  position: relative;
  width: 100%;
  height: 100%;
}
#preloader > h4 {
  top: 43%;
  margin: 0;
  margin-bottom: 50px;
  position: relative;
  display: block;
  opacity: 0;
  text-align: center;
  color: gray;
  transition: color 0.5s ease-in-out;
}
#preloader > #pre-text-2 {
  display: none;
  opacity: 0;
}
#preloader > .progress {
  top: 51%;
  left: 30%;
  width: 40%;
  height: 5px;
  position: absolute;
}
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  z-index: 2000;
  margin: auto;
  height: 5px;
  width: 100%;
  position: relative;
  background: transparent;
  overflow: hidden;
}
.pace .pace-progress {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: absolute;
  width: 100%;
  z-index: 2000;
  position: relative;
  right: 100%;
  height: 100%;
  width: 100%;
}
.pace.pace-inactive {
  display: none;
}
