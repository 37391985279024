.post-load {
  transition: transform 2s ease-in-out;
  transform: translate(0, 0) !important;
}

#preloader {
  position: relative;
  width: 100%;
  height: 100%;
}
#preloader > h4 {
  top: 43%;
  margin: 0;
  margin-bottom: 50px;
  position: relative;
  display: block;
  opacity: 0;
  text-align: center;
  color: gray;
  transition: color 0.5s ease-in-out;
}
#preloader > #pre-text-2 {
  display: none;
  opacity: 0;
}
#preloader > .progress {
  top: 51%;
  left: 30%;
  width: 40%;
  height: 5px;
  position: absolute;
}
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  user-select: none;

  z-index: 2000;
  margin: auto;
  height: 5px;
  width: 100%;
  position: relative;
  background: transparent;
  overflow: hidden;
}
.pace .pace-progress {
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  transform: translate3d(0, 0, 0);
  position: absolute;
  width: 100%;
  z-index: 2000;
  position: relative;
  right: 100%;
  height: 100%;
  width: 100%;
}
.pace.pace-inactive {
  display: none;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: Montserrat, Arial, sans-serif;
}
.App,
#root {
  height: 100vh;
  width: 100%;
  display: block;
  position: relative;
}
/*Header Tags*/
h1 {
  font-size: 12em;
}
h2 {
  font-size: 10em;
}
h3 {
  font-size: 8em;
  font-weight: 600;
}
h4 {
  font-size: 2.25em;
  font-weight: 700;
}
h5 {
  font-size: 1.8em;
  font-weight: 700;
}
/*Generic Container Styles*/
section {
  height: 100%;
  width: 100%;
  transition: opacity 0.2s ease-in-out;
}

@media all and (max-width: 615px) {
  body {
    font-size: 6px;
  }
  #pan {
    font-size: 10px;
  }
  #homelink-1,
  #aboutlink {
    width: 10% !important;
  }
  #homelink-1:hover,
  #aboutlink:hover {
    width: 12% !important;
  }

  /*about*/
  #about > article {
    padding: 0 8% !important;
  }
  #about > article > h1 {
    width: 67% !important;
  }
  #about > article > h5 {
    bottom: 10% !important;
  }
  #about > article:nth-child(2) {
    font-size: 8px;
  }
  #about > article:nth-child(2) > div {
    width: auto !important;
  }
  #about > article:nth-child(2) > div > div {
    width: 350px !important;
    min-height: 450px !important;
    padding: 30px !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
  }
  #about > article:nth-child(2) > div > img {
    display: none;
  }
  #about > article:nth-child(2) > div > div > div > p {
    font-size: 1em !important;
  }
  /*case*/
  #case p {
    font-size: 2em !important;
    text-indent: 30px !important;
  }
  #case a {
    font-size: 2em !important;
  }
  #case ol {
    padding-left: 12px !important;
    font-size: 2em !important;
  }
  #case .ci {
    margin: 25px auto 25px !important;
  }
  #case .c-i-c > img {
    max-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  #case .c-i-c > img:first-child {
    margin-bottom: 25px;
  }
  #case > #piece-cover > div > #title-tab {
    width: 70% !important;
    padding: 25px 15px 25px 25px !important;
  }
  #case > #piece-cover > div > #title-tab > h3 {
    font-size: 1em !important;
  }
  #case > #piece-cover > div > #title-tab > h2,
  #case > #introduction > #quick-info > #q-i-t > h2 {
    font-size: 1.5em !important;
  }
  #case > #introduction,
  #case > #process,
  #case > #result {
    padding: 50px 10% !important;
  }
  #case > #introduction > #quick-info > #q-i-t {
    width: 100% !important;
  }
  #case > #introduction > #quick-info > #q-i-l {
    display: none !important;
  }
}
@media all and (min-width: 615px) and (max-width: 889px) {
  body {
    font-size: 8px;
  }
  #pan {
    font-size: 10px;
  }
  #homelink-1,
  #aboutlink {
    width: 5% !important;
  }
  #homelink-1:hover,
  #aboutlink:hover {
    width: 8% !important;
  }

  #about > article {
    padding: 0 10%;
  }
  #about > article:nth-child(2) > div > div {
    width: 350px !important;
    min-height: 450px !important;
    padding: 30px !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
  }
  #about > article:nth-child(2) > div > img {
    display: none;
  }
  #about > article:nth-child(2) > div > div > div > p {
    font-size: 1em !important;
  }
}
@media all and (min-width: 890px) and (max-width: 1024px) {
  body {
    font-size: 10px;
  }
  #about > article:nth-child(2) > div > div {
    width: 350px !important;
    min-height: 450px !important;
    padding: 30px !important;
  }
  #about > article:nth-child(2) > div > div {
    width: 350px !important;
    min-height: 450px !important;
    padding: 30px !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
  }
  #about > article:nth-child(2) > div > img {
    display: none;
  }
  #about > article:nth-child(2) > div > div > div > p {
    font-size: 1em !important;
  }
}
@media all and (min-width: 1025px) and (max-width: 1310px) {
  body {
    font-size: 12px;
  }
  #about > article:nth-child(2) > div > div {
    width: 350px !important;
    min-height: 450px !important;
    padding: 35px !important;
  }
  #about > article:nth-child(2) > div:first-child > img {
    right: 0;
    height: 95%;
  }
  #about > article:nth-child(2) > div:first-child > div {
    left: 30%;
    top: 100px;
  }
  #about > article:nth-child(2) > div:nth-child(2) > img {
    left: 0;
    height: 95%;
  }
  #about > article:nth-child(2) > div:nth-child(2) > div {
    right: 30%;
    top: 30px;
  }
  #about > article:nth-child(2) > div:nth-child(3) > img {
    right: 0;
    height: 75%;
  }
  #about > article:nth-child(2) > div:nth-child(3) > div {
    left: 0;
    top: 0;
  }
  #about > article:nth-child(2) > div > div > div > p {
    font-size: 1em !important;
  }
}
@media all and (min-width: 1310px) and (max-width: 1600px) {
  body {
    font-size: 14px;
  }
  #about > article:nth-child(2) > div > div {
    width: 400px !important;
    min-height: 500px !important;
    padding: 40px !important;
  }
  #about > article:nth-child(2) > div:first-child > img {
    right: 0;
    height: 95%;
  }
  #about > article:nth-child(2) > div:first-child > div {
    left: 30%;
    top: 100px;
  }
  #about > article:nth-child(2) > div:nth-child(2) > img {
    left: 0;
    height: 95%;
  }
  #about > article:nth-child(2) > div:nth-child(2) > div {
    right: 30%;
    top: 80px;
  }
  #about > article:nth-child(2) > div:nth-child(3) > img {
    right: 0;
    height: 80%;
  }
  #about > article:nth-child(2) > div:nth-child(3) > div {
    left: 0;
    top: 20px;
  }
}
@media all and (min-width: 1601px) and (max-width: 1700px) {
  body {
    font-size: 16px;
  }
  #about > article:nth-child(2) > div > div {
    width: 450px !important;
    min-height: 550px !important;
    padding: 45px !important;
  }
  #about > article:nth-child(2) > div:first-child > img {
    right: 0;
    height: 95%;
  }
  #about > article:nth-child(2) > div:first-child > div {
    left: 30%;
    top: 150px;
  }
  #about > article:nth-child(2) > div:nth-child(2) > img {
    left: 0;
    height: 95%;
  }
  #about > article:nth-child(2) > div:nth-child(2) > div {
    right: 30%;
    top: 120px;
  }
  #about > article:nth-child(2) > div:nth-child(3) > img {
    right: 0;
    height: 80%;
  }
  #about > article:nth-child(2) > div:nth-child(3) > div {
    left: 0;
    top: 50px;
  }
}
@media all and (min-width: 1700px) {
  body {
    font-size: 18px;
  }
  #about > article:nth-child(2) > div:first-child > img {
    right: 0;
    height: 95%;
  }
  #about > article:nth-child(2) > div:first-child > div {
    left: 30%;
    top: 150px;
  }
  #about > article:nth-child(2) > div:nth-child(2) > img {
    left: 0;
    height: 95%;
  }
  #about > article:nth-child(2) > div:nth-child(2) > div {
    right: 30%;
    top: 120px;
  }
  #about > article:nth-child(2) > div:nth-child(3) > img {
    right: 0;
    height: 80%;
  }
  #about > article:nth-child(2) > div:nth-child(3) > div {
    left: 0;
    top: 80px;
  }
}

